import React, { useState } from 'react';
import './LoginForm.css';
import { FaUser, FaLock } from "react-icons/fa";


const LoginForm = () =>{

    const baseUrl = process.env.REACT_APP_BASE_URL;
    const loginUrl = `${baseUrl}users/login`;

    // State to hold form values
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    // State for loading, error and success messages
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();  // Prevent default form submission behavior

        setLoading(true);  // Set loading state to true while the request is being made
        setError('');      // Reset any previous errors
        setSuccess('');    // Reset success message

        const loginData = {
            username: username,
            password: password
        };

        try {
            // Send login data via AJAX (fetch or axios)
            const response = await fetch(loginUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginData),
            });

            const data = await response.json();

            // Handle response
            if (response.ok) {
                // Successful login (you can save the token or handle further logic)
                setSuccess('Login successful!');
                console.log('Login Success:', data);
                window.location.href = data.locat;
            } else {
                // Handle error (wrong credentials, server error, etc.)
                setError(data.message || 'Login failed. Please try again.');
            }
        } catch (err) {
            // Handle network or other errors
            setError('Something went wrong. Please try again later.');
        } finally {
            setLoading(false); // Set loading to false after request is complete
        }
    };

    return (
        <div className='wrapper'>
            <form onSubmit={handleSubmit}>
                <h1>Login</h1>
                {error && <div className="error-message">{error}</div>}
                {success && <div className="success-message">{success}</div>}
                <div className='input-box'>
                    <input type="text" placeholder='Username' value={username} 
                    onChange={(e) => setUsername(e.target.value)} required/>
                    <FaUser className='icon'/>

                </div>
                <div className='input-box'>
                    <input type="password" placeholder='Password' value={password}
                        onChange={(e) => setPassword(e.target.value)} required/>
                    <FaLock className='icon'/>

                </div>

                <div className="remember-forget">
                    <label htmlFor=""> <input type="checkbox" />Remember me</label>
                    <a href="#">Forgot password?</a>
                </div>

                <button type="submit" disabled={loading}>{loading ? 'Logging in...' : 'Login'}</button>
            </form>
        </div>
    );
}

export default LoginForm